<i18n lang="json">
{
  "en": {
    "metaTitle": "New & Used RVs for Sale",
    "metaDescription": "The best deals on new and used motorhomes and trailers. Explore RVs from private sellers and dealerships across the country. Financing available."
  },
  "fr": {
    "metaTitle": "VR neufs et d'occasion à vendre",
    "metaDescription": "Les meilleures offres sur les motorhomes et les remorques neufs et d'occasion. Explorez les VR de particuliers et de concessionnaires à travers le pays. Financement disponible."
  }
}
</i18n>

<template>
  <PageHomepageHeroSection />

  <div class="mb-6 mx-8 lg:mb-14 lg:max-w-[80rem] lg:mx-auto">
    <PageHomepageValueProposition />
    <PageHomepageSearchResults />
    <PageHomepageDealerships />
  </div>
</template>

<script setup lang="ts">
definePageMeta({
  layout: 'homepage'
})

const { t, locale } = useI18n({ useScope: 'local' })

const i18nHead = useLocaleHead({
  addSeoAttributes: true
})

useServerHead({
  title: `${t('metaTitle')} | RVezy Marketplace`,
  meta: [{ name: 'description', content: t('metaDescription') }]
})

useHead({
  link: [...(i18nHead.value.link || [])],
  htmlAttrs: {
    lang: i18nHead.value?.htmlAttrs?.lang || locale
  },
  meta: [...(i18nHead.value.meta || [])]
})
</script>
