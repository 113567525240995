<template>
  <div class="select-none flex flex-col relative" v-bind="$attrs">
    <div v-if="showControls" :class="controlClasses">
      <div :class="[navClasses, leftNavClasses]" @click="leftScrollHandler">
        <IconChevron direction="left" class="size-1/2" />
      </div>
      <div :class="[navClasses, rightNavClasses]" @click="rightScrollHandler">
        <IconChevron direction="right" class="size-1/2" />
      </div>
    </div>
    <div
      class="flex flex-no-wrap gap-12 overflow-x-scroll scrolling-touch items-start scrollbar-none relative"
      :class="shelfClasses"
      ref="shelfElement"
      @scroll="scrollHandler"
      @mousedown="shelfStartDraggingHandler"
      @mouseup="shelfStopDraggingHandler"
      @mousemove="shelfMoveHandler"
      @mouseleave="shelfStopDraggingHandler"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useResizeObserver } from '@vueuse/core'

type range = {
  start: number
  end: number
}

const props = withDefaults(
  defineProps<{
    step?: number
    wrapAround?: boolean
    position?:
      | 'top'
      | 'bottom'
      | 'middle-inset'
      | 'middle-outset'
      | 'top-left'
      | 'top-middle'
      | 'top-right'
      | 'bottom-left'
      | 'bottom-middle'
      | 'bottom-right'
    showControls?: boolean
    split?: boolean
  }>(),
  {
    step: 1,
    position: 'top-right',
    showControls: true,
    split: false
  }
)

const shelfElement = ref<InstanceType<typeof HTMLDivElement>>()
const isDragging = ref(false)
const dragStartX = ref(0)
const dragScrollLeft = ref(0)

useResizeObserver(shelfElement, () => {
  calculateView()
})

const breakpoints = reactive<range[]>([])
const currentView = reactive({
  width: 0,
  left: 0,
  right: 0
})

const leftScrollEnabled = computed(
  () => props.wrapAround || breakpoints.find((bp) => currentView.left > bp.start) != null
)

const rightScrollEnabled = computed(
  () => props.wrapAround || breakpoints.findLast((bp) => currentView.right < bp.end) != null
)

const shelfClasses = computed(() => {
  const classes: Array<string | object> = [
    { 'hover:cursor-grab': !isDragging.value, 'cursor-grabbing': isDragging.value }
  ]

  if (props.position === 'middle-outset' && props.showControls) {
    classes.push('mx-12')
  }

  return classes
})

const navClasses = computed(() => {
  const classes = [
    'flex',
    'size-8',
    'bg-primary-50',
    'cursor-pointer',
    '[&:is(.disabled)]:cursor-not-allowed',
    '[&:is(.disabled)]:text-gray-200',
    '[&:not(.disabled)]:hover:border-primary',
    'border-2',
    'border-transparent',
    'rounded-full',
    'items-center',
    'justify-center'
  ]

  if (['middle-inset', 'middle-outset'].includes(props.position)) {
    classes.push('absolute')
  }

  if (props.position === 'middle-inset') {
    classes.push('opacity-50', 'hover:opacity-100')
  }

  return classes
})

const leftNavClasses = computed(() => {
  const classes: Array<object | string> = [{ disabled: !leftScrollEnabled.value }]

  if (['middle-inset', 'middle-outset'].includes(props.position)) {
    classes.push('left-2')
  }

  return classes
})

const rightNavClasses = computed(() => {
  const classes: Array<object | string> = [{ disabled: !rightScrollEnabled.value }]

  if (['middle-inset', 'middle-outset'].includes(props.position)) {
    classes.push('right-2')
  }

  return classes
})

const controlClasses = computed(() => {
  const classes = []

  if (!['middle-inset', 'middle-outset'].includes(props.position)) {
    classes.push('flex', 'gap-3')
  } else {
    classes.push('w-full')
  }

  switch (props.position) {
    case 'top':
      classes.push('mb-2')
      break

    case 'bottom':
      classes.push('mt-2', 'order-last')
      break

    case 'middle-inset':
      classes.push('absolute', 'z-10', 'top-[calc(50%-1rem)]')
      break

    case 'middle-outset':
      classes.push('absolute', 'z-10', 'top-[calc(50%-1rem)]')
      break

    case 'top-left':
      classes.push('mb-2', 'justify-start')
      break

    case 'top-middle':
      classes.push('mb-2', 'mx-auto')
      break

    case 'top-right':
      classes.push('mb-2', 'justify-end')
      break

    case 'bottom-left':
      classes.push('mt-2', 'justify-start', 'order-last')
      break

    case 'bottom-middle':
      classes.push('mt-2', 'mx-auto', 'order-last')
      break

    case 'bottom-right':
      classes.push('mt-2', 'justify-end', 'order-last')
      break
  }

  if (props.split) {
    classes.push('justify-between')
  }

  return classes
})

const scrolledToEnd = computed(() => currentView.right >= currentView.width)

onMounted(() => {
  calculateBreakpoints()
  calculateView()
})

const scrollHandler = () => {
  calculateView()
}

const leftScrollHandler = () => {
  if (shelfElement.value == null || !leftScrollEnabled.value) return

  const index = Math.max(-1, breakpoints.findLastIndex((bp) => currentView.left > bp.start) - props.step + 1)
  const bp = breakpoints[index === -1 ? breakpoints.length - 1 : index]

  shelfElement.value.scrollTo({ left: bp.start, behavior: 'smooth' })
}

const rightScrollHandler = () => {
  if (shelfElement.value == null || !rightScrollEnabled.value) return

  const index = Math.min(breakpoints.length - 1, breakpoints.findIndex((bp) => bp.end > currentView.left) + props.step)
  const bp = breakpoints[scrolledToEnd.value ? 0 : index]

  shelfElement.value.scrollTo({ left: bp.start, behavior: 'smooth' })
}

const calculateBreakpoints = () => {
  if (shelfElement.value == null) return

  breakpoints.length = 0
  for (const child of shelfElement.value.children) {
    const c = child as HTMLElement
    breakpoints.push({ start: c.offsetLeft, end: c.offsetLeft + c.clientWidth })
  }
}

const calculateView = () => {
  if (shelfElement.value == null) return

  const visibleWidth = shelfElement.value.clientWidth ?? 0

  currentView.left = shelfElement.value.scrollLeft
  currentView.right = currentView.left + visibleWidth
  currentView.width = shelfElement.value.scrollWidth ?? 0
}

const shelfStartDraggingHandler = (e: MouseEvent) => {
  e.preventDefault()
  isDragging.value = true
  dragStartX.value = e.pageX - shelfElement.value!.offsetLeft
  dragScrollLeft.value = shelfElement.value!.scrollLeft
}

const shelfStopDraggingHandler = () => {
  isDragging.value = false
}

const shelfMoveHandler = (e: MouseEvent) => {
  e.preventDefault()
  if (!isDragging.value) return

  const x = e.pageX - shelfElement.value!.offsetLeft
  const scroll = x - dragStartX.value
  shelfElement.value!.scrollLeft = dragScrollLeft.value - scroll
}
</script>
