<i18n lang="json">
{
  "en": {
    "title": "Find the best dealerships across North America"
  },
  "fr": {
    "title": "Trouvez les meilleures concessions à travers l'Amérique du Nord"
  }
}
</i18n>

<template>
  <div v-if="!pending && dealershipList?.length">
    <div class="typography-subtitle-3 mb-4">{{ t('title') }}</div>
    <Shelf position="middle-outset" :show-controls="isLargeScreen">
      <template v-for="dealership in dealershipList" :key="dealership.id">
        <div class="size-24 flex-none" :title="dealership.name!">
          <NuxtLink
            v-if="dealership.websiteUrl"
            :href="dealership.websiteUrl"
            target="_blank"
            class="size-24 lg:size:32"
            @click.prevent="dealerClickHandler"
          >
            <img loading="lazy" class="size-full" :alt="dealership.name!" :src="dealership.logoUrl!" />
          </NuxtLink>
          <img v-else loading="lazy" class="size-full" :alt="dealership.name!" :src="dealership.logoUrl!" />
        </div>
      </template>
    </Shelf>
  </div>
</template>

<script lang="ts" setup>
import { SegmentEvents } from '~/enums/segment-events'
import { captureError } from '~/lib/logger'
import { segmentTrack } from '~/lib/tracking'

const isLargeScreen = useIsLargeScreen()

const { t } = useI18n({ useScope: 'local' })

const {
  pending,
  data: dealershipList,
  error: fetchError
} = await useAsyncData('basic-dealership', async () => {
  const { data: featuredDealers } = await marketplaceApiClient().GET('/Dealer/Featured')

  return featuredDealers?.results
})

if (fetchError.value) captureError(toRaw(fetchError.value))

const dealerClickHandler = async ({ currentTarget: target }: { currentTarget: HTMLAnchorElement }) => {
  segmentTrack(SegmentEvents.ExternalLinkClicked, {
    externalLink: target.href,
    name: 'DealershipLogoClicked',
    cta: 'dealership-carousel'
  })

  await windowNavigateTo(target)
}
</script>
