<i18n lang="json">
{
  "en": {
    "title": "Buy your perfect RV",
    "rentalIncomeBubble": {
      "title": "This RV makes",
      "amount": "3,000",
      "perMonth": "/month"
    }
  },
  "fr": {
    "title": "Achetez votre VR parfait",
    "rentalIncomeBubble": {
      "title": "Ce VR rapporte",
      "amount": "3 000",
      "perMonth": "/mois"
    }
  }
}
</i18n>

<template>
  <div
    :class="[
      'relative bg-no-repeat bg-cover bg-right text-white bg-primary rounded-b-3xl',
      '-top-14 h-[35rem]',
      `bg-[image:var(--mobile-background-url)]`,
      'lg:-top-20 lg:-mb-4 lg:h-[80dvh] lg:min-h-[30rem] lg:max-h-[42rem]',
      `lg:bg-[image:var(--desktop-background-url)]`
    ]"
    :style="tailwindsCssVars"
    ref="container"
  >
    <div class="size-full flex flex-col items-baseline bg-gradient-to-b from-black/40">
      <div
        :class="[
          'flex flex-col items-start max-w-full pt-20',
          'lg:relative lg:top-56 lg:left-32 lg:pt-0 lg:mt-0 lg:w-[calc(100%-8rem)]'
        ]"
      >
        <h1
          :class="[
            'mb-5 self-center',
            'lg:self-start',
            {
              'typography-title-2': !isLargeScreen,
              'typography-title-5': isLargeScreen
            }
          ]"
        >
          {{ t('title') }}
        </h1>
        <LazySearchInputHomepage />
      </div>

      <div
        class="hidden align-center rounded-3xl bg-white/90 text-primary absolute p-5 lg:size-40 lg:bottom-[45%] lg:right-[12%] lg:flex"
      >
        <div class="flex flex-col">
          <div class="typography-subtitle-1">{{ t('rentalIncomeBubble.title') }}</div>
          <div class="flex gap 1 items-center text-highlight">
            <div class="typography-subtitle-1">$</div>
            <div class="typography-subtitle-4">{{ t('rentalIncomeBubble.amount') }}</div>
          </div>
          <div class="typography-body-1 -mt-2">{{ t('rentalIncomeBubble.perMonth') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n({ useScope: 'local' })
const isLargeScreen = useIsLargeScreen()
const $appConfig = useRuntimeConfig().public.app

const container = ref<HTMLElement>()

// tailwinds doesn't handle dynamic images, this is the workaround
const tailwindsCssVars = {
  '--desktop-background-url': `url('${$appConfig.cdnEndpoint}/images/marketplace-class-c-desktop.webp')`,
  '--mobile-background-url': `url('${$appConfig.cdnEndpoint}/images/marketplace-class-c-mobile.webp')`
}
</script>
