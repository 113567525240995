<i18n lang="json">
{
  "en": {
    "showMeMore": "Show me more",
    "title": "RVs for sale near you"
  },
  "fr": {
    "showMeMore": "Montrez-moi plus",
    "title": "VR à vendre près de chez vous"
  }
}
</i18n>

<template>
  <div class="mb-14">
    <h1 class="text-3xl mb-8 font-semibold">{{ t('title') }}</h1>
    <div class="mb-8 grid gap-y-8 gap-x-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      <RvCard
        v-for="(rv, index) in rvList"
        :key="index"
        :rv="rv"
        :index="index"
        :loading="pending"
        :cta="Ctas.HomePageResults"
        page-source="homepage"
      />
    </div>
    <NuxtLink class="underline text-highlight font-semibold text-2xl" :to="getLocalePath('/search')">{{
      t('showMeMore')
    }}</NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { Ctas } from '~/enums/ctas'
import { captureError } from '~/lib/logger'
import type { RvSearchRequest } from '~/types/api'

const { t } = useI18n({ useScope: 'local' })
const geolocation = useGeolocation()
const $config = useRuntimeConfig()

const {
  pending,
  data: searchResults,
  error: fetchError
} = await useAsyncData('rv-search-results', async () => {
  const requestPayload = {
    Latitude: geolocation?.latitude ?? $config.public.app.defaultLatitude,
    Longitude: geolocation?.longitude ?? $config.public.app.defaultLongitude,
    PageSize: 15,
    OrderBy: 'Default'
  } as RvSearchRequest

  const { data: searchResults } = await marketplaceApiClient().GET('/Rv/Search', { params: { query: requestPayload } })

  return searchResults
})

const rvList = computed(() => searchResults.value?.results ?? [])

if (fetchError.value) captureError(toRaw(fetchError.value))
</script>
