<i18n lang="json">
{
  "en": {
    "makeMoney": {
      "title": "Boost your RV payments",
      "text": "With RVezy, motorhomes make on average $3,000/month and trailers make $1,500/month.",
      "link": "Learn more"
    },
    "similarRvs": {
      "title": "Need help choosing the right RV?",
      "text": "With RVezy, you can rent similar RVs to the one you want to buy.",
      "link": "Visit RVezy rentals"
    }
  },
  "fr": {
    "makeMoney": {
      "title": "Augmenter vos paiements de VR",
      "text": "Avec RVezy, les camping-cars rapportent en moyenne $3 000/mois et les roulottes $1 500/mois.",
      "link": "En savoir plus"
    },
    "similarRvs": {
      "title": "Besoin d'aide pour choisir le bon VR ?",
      "text": "Avec RVezy, vous pouvez louer des VR similaires à celui que vous voulez acheter.",
      "link": "Visitez les locations RVezy"
    }
  }
}
</i18n>

<template>
  <div class="grid gap-10 grid-cols-1 mb-14 lg:h-44 md:flex-row md:grid-cols-2">
    <Notice class="h-[12.5rem] lg:h-44">
      <template #image>
        <div class="bg-highlight-300 w-28 h-full lg:w-44">
          <img
            :src="`${$appConfig.cdnEndpoint}/images/Campervan.png`"
            class="object-cover object-right-bottom size-full"
          />
        </div>
      </template>
      <template #title>
        <div
          :class="['mb-2', { 'typography-subtitle-1': isLargeScreen, 'typography-caption-strong-1': !isLargeScreen }]"
        >
          {{ t('makeMoney.title') }}
        </div>
      </template>
      <template #content>
        <div :class="['mb-2', { 'typography-body-1': isLargeScreen, 'typography-caption-': !isLargeScreen }]">
          {{ t('makeMoney.text') }}
        </div>
        <NuxtLink
          :href="`${$appConfig.rentals.webUrl}/owner`"
          target="_blank"
          class="typography-caption-link typography-link-1 flex gap-2 items-center"
          @click.prevent="learnMoreClickHandler"
        >
          {{ t('makeMoney.link') }} <IconChevron direction="right" class="size-3" />
        </NuxtLink>
      </template>
    </Notice>

    <Notice class="h-[12.5rem] lg:h-44">
      <template #image>
        <div class="bg-highlight-600 w-28 h-full lg:w-44">
          <img :src="`${$appConfig.cdnEndpoint}/images/phone.png`" class="object-cover object-bottom size-full" />
        </div>
      </template>
      <template #title>
        <div :class="['mb-2', { 'typography-subtitle-1': isLargeScreen, 'typography-strong-1': !isLargeScreen }]">
          {{ t('similarRvs.title') }}
        </div>
      </template>
      <template #content>
        <div :class="['mb-2', { 'typography-body-1': isLargeScreen, 'typography-caption-': !isLargeScreen }]">
          {{ t('similarRvs.text') }}
        </div>
        <NuxtLink
          :href="$appConfig.rentals.webUrl"
          target="_blank"
          class="typography-caption-link typography-link-1 flex gap-2 items-center"
          @click.prevent="rentalsClickHandler"
        >
          {{ t('similarRvs.link') }} <IconChevron direction="right" class="size-3" />
        </NuxtLink>
      </template>
    </Notice>
  </div>
</template>

<script lang="ts" setup>
import { SegmentEvents } from '~/enums/segment-events'
import { segmentTrack } from '~/lib/tracking'

const { t } = useI18n({ useScope: 'local' })
const $appConfig = useRuntimeConfig().public.app
const isLargeScreen = useIsLargeScreen()

const learnMoreClickHandler = async ({ currentTarget: target }: { currentTarget: HTMLAnchorElement }) => {
  segmentTrack(SegmentEvents.ExternalLinkClicked, {
    externalLink: target.href,
    name: 'LearnMore',
    cta: 'value-proposition'
  })

  await windowNavigateTo(target)
}

const rentalsClickHandler = async ({ currentTarget: target }: { currentTarget: HTMLAnchorElement }) => {
  segmentTrack(SegmentEvents.ExternalLinkClicked, {
    externalLink: target.href,
    name: 'VisitRentals',
    cta: 'value-proposition'
  })

  await windowNavigateTo(target)
}
</script>
